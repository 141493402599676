<template>
  <div v-if="loaded">
    <div
      class="accordion"
      id="accordionClassificaPersona"
      v-if="
        classifica.length != 0 &&
        (classifica[0].tennis[0] != undefined ||
          classifica[0].padel[0] != undefined ||
          classifica[0].beach[0] != undefined) &&
        !error
      "
    >
      <div class="card shadow mb-7" v-if="classifica[0].tennis[0] != undefined">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionClassificaPersona-headingOne"
          >
            <button
              class="accordion-button collapsed p-0 m-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionClassificaPersona-collapseOne"
              aria-expanded="false"
              aria-controls="accordionClassificaPersona-collapseOne"
            ></button>
            <div
              class="table-responsive"
              style="border-bottom: 1px solid #f8f9fa"
            >
              <table class="table gy-3 gs-7 align-middle">
                <thead
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionClassificaPersona-collapseOne"
                  aria-expanded="false"
                  aria-controls="accordionClassificaPersona-collapseOne"
                >
                  <tr
                    class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                  >
                    <th scope="col"></th>
                    <th scope="col">Disciplina</th>
                    <th scope="col">Categoria</th>
                    <th scope="col">Gruppo</th>
                    <th scope="col">Data inserimento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(classifica, index) in getArrayLastYear(
                      classifica[0].tennis,
                      classifica[0].tennis[0].stagione
                    )"
                    :key="index"
                  >
                    <td class="ps-7 pt-8 mt-8" style="width: 20%">
                      {{ classifica.stagione }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.disciplina_view }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.categoria_view }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.gruppo }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.data_inserimento }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </h6>
          <div
            id="accordionClassificaPersona-collapseOne"
            class="accordion-collapse collapse"
            aria-labelledby="accordionClassificaPersona-headingOne"
          >
            <div class="accordion-body p-0 m-0">
              <div class="table-responsive ps-0 pe-0">
                <table class="table gy-3 gs-7 align-middle">
                  <tbody>
                    <tr
                      v-for="(classifica, index) in getArrayOtherYears(
                        classifica[0].tennis,
                        classifica[0].tennis[0].stagione
                      )"
                      :key="index"
                    >
                      <td class="ps-7 pt-4" style="width: 20%">
                        {{ classifica.stagione }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.disciplina_view }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.categoria_view }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.gruppo }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.data_inserimento }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow mb-7" v-if="classifica[0].padel[0] != undefined">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionClassificaPersona-headingTwo"
          >
            <button
              class="accordion-button collapsed p-0 m-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionClassificaPersona-collapseTwo"
              aria-expanded="false"
              aria-controls="accordionClassificaPersona-collapseTwo"
            ></button>
            <div
              class="table-responsive"
              style="border-bottom: 1px solid #f8f9fa"
            >
              <table class="table gy-3 gs-7 align-middle">
                <thead
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionClassificaPersona-collapseTwo"
                  aria-expanded="false"
                  aria-controls="accordionClassificaPersona-collapseTwo"
                >
                  <tr
                    class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                  >
                    <th scope="col"></th>
                    <th scope="col">Disciplina</th>
                    <th scope="col">Categoria</th>
                    <th scope="col">Gruppo</th>
                    <th scope="col">Data inserimento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(classifica, index) in getArrayLastYear(
                      classifica[0].padel,
                      classifica[0].padel[0].stagione
                    )"
                    :key="index"
                  >
                    <td class="ps-7 pt-8 mt-8" style="width: 20%">
                      {{ classifica.stagione }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.disciplina_view }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.categoria_view }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.gruppo }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.data_inserimento }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </h6>
          <div
            id="accordionClassificaPersona-collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="accordionClassificaPersona-headingTwo"
          >
            <div class="accordion-body p-0 m-0">
              <div class="table-responsive ps-0 pe-0">
                <table class="table gy-3 gs-7 align-middle">
                  <tbody>
                    <tr
                      v-for="(classifica, index) in getArrayOtherYears(
                        classifica[0].padel,
                        classifica[0].padel[0].stagione
                      )"
                      :key="index"
                    >
                      <td class="ps-7 pt-4" style="width: 20%">
                        {{ classifica.stagione }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.disciplina_view }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.categoria_view }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.gruppo }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.data_inserimento }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card shadow mb-7" v-if="classifica[0].beach[0] != undefined">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionClassificaPersona-headingThree"
          >
            <button
              class="accordion-button collapsed p-0 m-0"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionClassificaPersona-collapseThree"
              aria-expanded="false"
              aria-controls="accordionClassificaPersona-collapseThree"
            ></button>
            <div
              class="table-responsive"
              style="border-bottom: 1px solid #f8f9fa"
            >
              <table class="table gy-3 gs-7 align-middle">
                <thead
                  data-bs-toggle="collapse"
                  data-bs-target="#accordionClassificaPersona-collapseThree"
                  aria-expanded="false"
                  aria-controls="accordionClassificaPersona-collapseThree"
                >
                  <tr
                    class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
                  >
                    <th scope="col"></th>
                    <th scope="col">Disciplina</th>
                    <th scope="col">Categoria</th>
                    <th scope="col">Gruppo</th>
                    <th scope="col">Data inserimento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(classifica, index) in getArrayLastYear(
                      classifica[0].beach,
                      classifica[0].beach[0].stagione
                    )"
                    :key="index"
                  >
                    <td class="ps-7 pt-8 mt-8" style="width: 20%">
                      {{ classifica.stagione }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.disciplina_view }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.categoria_view }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.gruppo }}
                    </td>
                    <td class="pt-8 mt-8" style="width: 20%">
                      {{ classifica.data_inserimento }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </h6>
          <div
            id="accordionClassificaPersona-collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="accordionClassificaPersona-headingThree"
          >
            <div class="accordion-body p-0 m-0">
              <div class="table-responsive ps-0 pe-0">
                <table class="table gy-3 gs-7 align-middle">
                  <tbody>
                    <tr
                      v-for="(classifica, index) in getArrayOtherYears(
                        classifica[0].beach,
                        classifica[0].beach[0].stagione
                      )"
                      :key="index"
                    >
                      <td class="ps-7 pt-4" style="width: 20%">
                        {{ classifica.stagione }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.disciplina_view }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.categoria_view }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.gruppo }}
                      </td>
                      <td class="pt-4" style="width: 20%">
                        {{ classifica.data_inserimento }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="
        (classifica.length &&
          classifica[0].tennis[0] == undefined &&
          classifica[0].padel[0] == undefined &&
          classifica[0].beach[0] == undefined &&
          !error) ||
        (!classifica.length && !error)
      "
      class="ps-5"
    >
      <span class="text-gray-700 ps-5 fs-3">Nessun risultato.</span>
    </div>
    <div v-else>
      <span class="text-gray-700 ps-3 fs-2">
        Attenzione! Si è verificato un errore. Riprovare più tardi.
      </span>
      <span
        type="button"
        data-bs-toggle=""
        data-bs-target=""
        aria-expanded="false"
        aria-controls=""
        class="text-gray-600 pt-4"
      >
        <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
        <a
          v-else-if="!hasBacheca"
          :href="redirectInsegnanti"
          class="btn text-hover-primary text-gray-600 fs-5 text-end p-0 m-0"
        >
          <i class="bi bi-house fs-2 text-gray-800"> </i>
        </a>
        <router-link v-else to="/bacheca-public">
          <i class="bi bi-house fs-2 text-gray-800"> </i
        ></router-link>
      </span>
    </div>
  </div>
  <div v-else class="mx-auto my-auto text-center">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRedirectInsegnanti } from "@/composables/redirectInsegnanti";

import services from "../../../axios/dbManag";

import isEnabled from "@/composables/isEnabled.js";

export default defineComponent({
  name: "classifica-persona",
  props: ["persona"],
  components: {},
  setup(props) {
    const classifica = ref([]);
    const loaded = ref(false);
    const error = ref(false);

    services.dbManag
      .post(`/anagrafiche/persone/ranking/list`, {
        id_persona: props.persona.id,
      })
      .then((res) => {
        res.data.results.forEach((rank) => {
          classifica.value.push(rank);
        });
        loaded.value = true;
      })
      .catch((e) => {
        error.value = true;
        loaded.value = true;
      });
    onMounted(() => {
      setCurrentPageBreadcrumbs("Classifica Persona", [
        "Lista Persone",
        "Dettaglio Persona",
      ]);
    });
    //funzione gestione anno
    const getArrayLastYear = (arrayToFilter, lastYear) => {
      const array = arrayToFilter.filter((item) => {
        return item.stagione === lastYear;
      });
      return array;
    };
    const getArrayOtherYears = (arrayToFilter, lastYear) => {
      const array = arrayToFilter.filter((item) => {
        return item.stagione != lastYear;
      });
      return array;
    };

    const { pagesLength, redirectInsegnanti, menuLoaded, hasBacheca } =
      useRedirectInsegnanti();

    return {
      getArrayLastYear,
      getArrayOtherYears,
      classifica,
      loaded,
      error,

      pagesLength,
      redirectInsegnanti,
      menuLoaded,
      hasBacheca,

      isEnabled,
    };
  },
});
</script>

<style scoped>
.form-control {
  height: 28px !important;
}

.form-control-sm {
  width: 5%;
}
.form-select {
  height: 28px;
}

.table-responsive {
  min-height: none !important;
  min-width: 99%;
}

.table tr,
.table th,
.table td {
  font-size: 13px;
  border-bottom: solid #f8f9fa;
}

.accordion-button::after {
  margin-top: 3rem !important;
  margin-left: 1.7rem !important;
}

.accordion-button {
  height: 0px;
  width: 0px;
}

.table-responsive {
  min-height: 0% !important;
}
.accordion .accordion-header {
  cursor: unset;
}
</style>

<style>
div.col-2.h-28 > div > div > div > input {
  height: 28px !important;
}

div > span > div > div > div > input {
  height: 28px !important;
}
</style>
